/* Fonts */
$serif: 'Athelas', 'Times New Roman', serif;
$sans-serif: '-apple-system', 'BlinkmacSystemFont', 'Franklin Gothic', 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;

/* Keyframes */
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateY(46px);
    opacity: 0;
  }
}

/* Box sizing partial styles: */
/* Apply a natural box layout model to all elements */
*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Styling on warning for users on IE7 or below */
.browsehappy {
  background: #fff8e2;
  text-align: center;
  padding: 0.5em 0;
  margin: 0;
  color: white;
}

html {
  background: black;
}

/* App */
body {
  font-family: $sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -webkit-overflow-scrolling: touch;
  background: black;
  transition: all 0.2s ease-in-out;
}

a,
input,
button,
textarea {
  transition: all 0.2s ease-in-out;
  border: none;

  &:focus {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
    outline: none;
  }
}

img.fit {
  object-fit: cover;
  overflow: hidden;
}

.navbar-brand {
  .logo {
    width: 2rem;
  }
}

.shadow-light {
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.075);
}

.font-display {
  font-family: $serif;
}

.logo-bg {
  position: absolute;
  opacity: 0.15; 
  height: 100%;
  width: auto;
  left: 0;
  top: 0;
}